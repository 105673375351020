
export const basicOptions = {
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    responsive: true,
  };
  export const basicOptionsDou = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
        padding: {
            left: 0,
            right: 90,
            top: 0,
            bottom: 0
        }
    },
    legend: {
      display: true,
      labels: {
        fontColor: '#000',
        boxWidth: 12,
        fontFamily: "sans-serif",
        usePointStyle: true,
        padding: 20,
        fontSize: 16
      },
      position: "right",
    },
    legendCallback: function(chart) {
      let legendHtml = [];
      let item = chart.data.datasets[0];
      let count = item.data.length;
      if(count == 3){
        count = "top: 44%;"
      }
      else if(count == 1){
        count = "top: 47%;"
      }
      else if(count == 5){
        count = "top: 30%;"
      }
      else{
        count = "top: 50%;"
      }
      legendHtml.push('<ul style="padding: 0; list-style: none; position: absolute;right: 34px;'+count+' ">');
      
      let total = 0;
      item.data.forEach((x,i) =>{ 
        total = total + x;
      })
      for (var i=0; i < item.data.length; i++) {
          let porcentaje = item.data[i] * 100 / total
          Number.isInteger(item.data[i] * 100 / total) ? porcentaje : porcentaje = porcentaje.toFixed(3);
          legendHtml.push('<li>');
          legendHtml.push('<span class="chart-legend-label-text">' + porcentaje + ' % </span>');
          legendHtml.push('</li>');
      }

      legendHtml.push('</ul>');
      return legendHtml.join("");
    },
    borderAlign: "inner",
  };
  export const basicOptionsDouSe = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        fontColor: '#000',
        boxWidth: 15,
        fontFamily: "sans-serif",
        padding: 10,
        fontSize: 15,
      },
    },
    legendCallback: function(chart) {
      var text = [];
      text.push('<ul class="legend">');
      var ds = chart.data.datasets[0];
      var sum = ds.data.reduce(function add(a, b) {
        return a + b;
      }, 0);
      for (var i = 0; i < ds.data.length; i++) {
        text.push('<li>');
        var perc = Math.round(100 * ds.data[i] / sum, 0);
        text.push('<p class="porce"><span style="background-color:' + ds.backgroundColor[i] + '"></span>' + chart.data.labels[i] +'</p><p>'+ perc + '%</p>');
        text.push('</li>');
      }
      text.push('</ul><div class="menci-resul"><p class="res-men">'+sum+'</p><p>menciones</p></div>');
      return text.join("");
    },
    responsive: true,
    borderAlign: "inner",
  };
  export let stadisticsGreen = {
    ...basicOptions,
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    scales: {
      yAxes: [{
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.0)',
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 50,
          suggestedMax: 110,
          padding: 20,
          fontColor: "#ff8a76",
          display: false
        }
      }],
  
      xAxes: [{
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(220,53,69,0.1)',
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#ff8a76",
          display: false
        }
      }]
    }
  }
  export let blueChartOptions = {
    ...basicOptions,
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    scales: {
      yAxes: [{
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.0)',
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#2380f7"
        }
      }],
  
      xAxes: [{
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.1)',
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#2380f7"
        }
      }]
    }
  }
  
  export let purpleChartOptions = {
    ...basicOptions,
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    scales: {
      yAxes: [{
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.0)',
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }],
  
      xAxes: [{
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(225,78,202,0.1)',
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }]
    }
  }
  
  export let orangeChartOptions = {
    ...basicOptions,
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    scales: {
      yAxes: [{
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.0)',
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 50,
          suggestedMax: 110,
          padding: 20,
          fontColor: "#ff8a76"
        }
      }],
  
      xAxes: [{
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(220,53,69,0.1)',
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#ff8a76"
        }
      }]
    }
  
  }
  export let greenChartOptions = {
    ...basicOptions,
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 10,
      xPadding: 30,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    scales: {
      yAxes: [{
        barPercentage: 0.6,
        gridLines: {
          drawBorder: true,
          color: 'rgba(29,140,248,0.0)',
          zeroLineColor: "transparent",
        },
        ticks: {
          //suggestedMin: 40,
          //suggestedMax: 1,
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }],
  
      xAxes: [{
        barPercentage: 1.6,
        gridLines: {
          drawBorder: true,
          color: 'rgba(0,242,195,0.1)',
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }]
    }
  }
  
  export let barChartOptions = {
    ...basicOptions,
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    scales: {
      yAxes: [{
  
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.1)',
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 120,
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }],
      xAxes: [{
  
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.1)',
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9e9e9e"
        }
      }]
    }
  
  }
  

  export let doughnutChartOptions = {
    ...basicOptionsDouSe,
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    }
    
  }
  export let doughnutChartOptionsFuentes = {
    ...basicOptionsDou,
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    }
  }
  
