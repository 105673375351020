import { Line, mixins } from 'vue-chartjs';
import { generateChart } from './BaseCharts'

Chart.defaults.LineWithLine = Chart.defaults.line
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  draw: function (ease) {
    Chart.controllers.line.prototype.draw.call(this, ease)

    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      let activePoint = this.chart.tooltip._active[0]
      let ctx = this.chart.ctx
      let x = activePoint.tooltipPosition().x
      let topY = this.chart.scales['y-axis-0'].top
      let bottomY = this.chart.scales['y-axis-0'].bottom

      // draw line
      ctx.save()
      ctx.beginPath()
      ctx.moveTo(x, topY)
      ctx.lineTo(x, bottomY)
      ctx.lineWidth = 2
      ctx.strokeStyle = '#07C'
      ctx.stroke()
      ctx.restore()
    }
  }
})

const LineWithLine = generateChart('line-with-chart', 'LineWithLine')

export default {
    name: 'Line',
    extends: Line,
    mixins: [mixins],
    props: {
        extraOptions: {
            type: Object,
            default: null
        },
        chartData: {
            type: Array,
            default: null
        },
    },
    data() {
        return {
            ctx: null
        };
    },
    methods: {
        updateGradients(chartData) {
            if (!chartData) return;
            const ctx = this.ctx || document.getElementById(this.chartId).getContext('2d');
            const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

            gradientStroke.addColorStop(this.gradientStops[0], this.gradientColors[0]);
            gradientStroke.addColorStop(this.gradientStops[1], this.gradientColors[1]);
            gradientStroke.addColorStop(this.gradientStops[2], this.gradientColors[2]);
            chartData.datasets.forEach(set => {
                set.backgroundColor = gradientStroke;
            });
        }
    },
    mounted() {
        //console.log(this.chartData);
        const totals = this.chartData.map(d => d.total);
        const labels = this.chartData.map(d => d.label);
        const peua = totals.filter(otro => otro != undefined);
        this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
        this.gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
        this.gradient3 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);

        this.gradient.addColorStop(0, 'rgba(163, 160, 251, 0.6)')
        this.gradient.addColorStop(0.5, 'rgba(163, 160, 251, 0.25)');
        this.gradient.addColorStop(1, 'rgba(163, 160, 251, 0)');

        this.gradient2.addColorStop(0, 'rgba(84, 216, 255, 0.6)')
        this.gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)');
        this.gradient2.addColorStop(1, 'rgba(163, 160, 251, 0)');

        this.gradient3.addColorStop(0, 'rgba(228, 64, 95, 1)')
        this.gradient3.addColorStop(0.5, 'rgba(228, 64, 95, 0.25)');
        this.gradient3.addColorStop(1, 'rgba(228, 64, 95, 0)');
        peua.forEach((item, index) => {
            item.pointBorderWidth = 10;
            if(item.label == "Facebook" ){
                item.backgroundColor = this.gradient;
                item.borderColor = this.gradient;
                item.pointBackgroundColor = "rgb(163, 160, 251)";
            }
            else if(item.label == "Instagram" || item.competidor == false){
                item.backgroundColor = this.gradient3;
                item.borderColor = this.gradient3;
                item.pointBackgroundColor = "rgba(228, 64, 95)"
            }
            else{
                item.backgroundColor = this.gradient2;
                item.borderColor = this.gradient2;                
                item.pointBackgroundColor = "rgba(84, 216, 255)";
            }
        })

        this.renderChart({
                labels: labels,
                datasets: peua
            }, this.extraOptions, { responsive: true, maintainAspectRatio: false })
    },
    watch: {
        chartData: function() {
            const totals = this.chartData.map(d => d.total);
            const labels = this.chartData.map(d => d.label);
            const peua = totals.filter(otro => otro != undefined);
            const labelfinal = labels.filter(otro => otro != undefined);
            this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
            this.gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
            this.gradient3 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
            this.gradient.addColorStop(0, 'rgba(163, 160, 251, 0.6)')
            this.gradient.addColorStop(0.5, 'rgba(163, 160, 251, 0.25)');
            this.gradient.addColorStop(1, 'rgba(163, 160, 251, 0)');
    
            this.gradient2.addColorStop(0, 'rgba(84, 216, 255, 0.6)')
            this.gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)');
            this.gradient2.addColorStop(1, 'rgba(163, 160, 251, 0)');
    
            this.gradient3.addColorStop(0, 'rgba(228, 64, 95, 1)')
            this.gradient3.addColorStop(0.5, 'rgba(228, 64, 95, 0.25)');
            this.gradient3.addColorStop(1, 'rgba(228, 64, 95, 0)');
            peua.forEach((item, index) => {
                item.pointBorderWidth = 10;
                if(item.label == "Facebook" ){
                    item.backgroundColor = this.gradient;
                    item.borderColor = this.gradient;
                    item.pointBackgroundColor = "rgb(163, 160, 251)";
                }
                else if(item.label == "Instagram" || item.competidor == false){
                    item.backgroundColor = this.gradient3;
                    item.borderColor = this.gradient3;
                    item.pointBackgroundColor = "rgba(228, 64, 95)"
                }
                else{
                    item.backgroundColor = this.gradient2;
                    item.borderColor = this.gradient2;                
                    item.pointBackgroundColor = "rgba(84, 216, 255)";
                }
                
            })
            this.renderChart({
                labels: labelfinal,
                datasets: peua
            }, this.extraOptions, { responsive: true, maintainAspectRatio: false })
        }
    },
};