import { Doughnut, mixins } from 'vue-chartjs';

export default {
    name: "doughnut-chart",
    extends: Doughnut,
    mixins: [mixins],
    props: {
        extraOptions: Object,
        chartData: {
            type: Array,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            ctx: null,
            // message: 'Hello World',
            // htmlLegend: null
        };
    },
    methods: {

        legendClickCallback(event) {
            event = event || window.event;
          
            var target = event.target || event.srcElement;
            while (target.nodeName !== 'LI') {
              target = target.parentElement;
            }
            var parent = target.parentElement;
            var chartId = parseInt(parent.classList[0].split("-")[0], 10);
            var chart = Chart.instances[chartId];
            var index = Array.prototype.slice.call(parent.children).indexOf(target);
            var meta = chart.getDatasetMeta(0);
            console.log(index);
            var item = meta.data[index];
          
            if (item.hidden === null || item.hidden === false) {
              item.hidden = true;
              target.classList.add('hidden');
            } else {
              target.classList.remove('hidden');
              item.hidden = null;
            }
            chart.update();
          }
    },    
    mounted() {
        const totals = this.chartData.map(d => d.post);
        const labels = this.chartData.map(d => d.resocial);
        let colors= [];
        labels.forEach(item => {
            if(item == "Negativo"){
                colors.push("#E44E48");
            }
            else if(item =="Neutral"){
                colors.push("#EAEAEA");
            }
            else if(item =="Positivo"){
                colors.push("#5BC542");
            }
            else if(item == "Facebook"){
                colors.push("rgb(163, 160, 251)");
                this.$refs.canvas.parentNode.style.height = '200px';
            }
            else if(item == "Instagram"){
                colors.push("rgba(228, 64, 95)");
            }
            else if(item == "Likes"){
                colors.push("rgb(163, 160, 251)");
            }
            else if(item == "Me enoja"){
                colors.push("rgba(228, 78, 72)");
            }
            else if(item == "Me divierte"){
                colors.push("rgb(242, 221, 11)");
            }
            else if(item == "Me sorprende"){
                colors.push("rgb(239, 147, 27)");
            }
            else if(item == "Me encanta"){
                colors.push("rgb(248, 84, 111)");
            }
            else{
                colors.push("rgba(84, 216, 255)");
            }
        })
        this.renderChart({
            labels: labels,
            datasets: [{
                backgroundColor: colors,
                data: totals
            }]
        }, this.options)
        const HTML = this.$data._chart.generateLegend()
        this.$emit('generated', HTML)
        // this.chartId == "doiun"
        
        
    },
    watch: {
        chartData: function() {
            const totals = this.chartData.map(d => d.post);
            const labels = this.chartData.map(d => d.resocial);
            let colors= [];
            labels.forEach(item => {
                if(item == "Negativo"){
                    colors.push("#E44E48");
                }
                else if(item =="Neutral"){
                    colors.push("#EAEAEA");
                }
                else if(item =="Positivo"){
                    colors.push("#5BC542");
                }
                else if(item == "Facebook"){
                    colors.push("rgb(163, 160, 251)");
                    this.$refs.canvas.parentNode.style.height = '200px';
                }
                else if(item == "Instagram"){
                    colors.push("rgba(228, 64, 95)");
                }
                else if(item == "Likes"){
                    colors.push("rgb(163, 160, 251)");
                }
                else if(item == "Me enoja"){
                    colors.push("rgba(228, 78, 72)");
                }
                else if(item == "Me divierte"){
                    colors.push("rgb(242, 221, 11)");
                }
                else if(item == "Me sorprende"){
                    colors.push("rgb(239, 147, 27)");
                }
                else if(item == "Me encanta"){
                    colors.push("rgb(248, 84, 111)");
                }
                else{
                    colors.push("rgba(84, 216, 255)");
                }
            })

            this.renderChart({
                labels: labels,
                datasets: [{
                    backgroundColor: colors,
                    data: totals
                }]
            },this.options)
            const HTML = this.$data._chart.generateLegend()
            this.$emit('generated', HTML)
        }
    }
}